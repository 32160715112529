@import '../mixins.scss';

.main {
  color: var(--background-light);
  background-color: var(--main);
  padding-top: var(--bootstrap-padding);
  padding-bottom: var(--bootstrap-padding);
  margin-bottom: var(--bootstrap-padding);

  h1 {
    @include pagetitle;
  }
}
