.main {
  position: relative;
  display: flex;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    height: 2em;
    width: calc(100% - 1rem);
    bottom: 0;
    display: block;
    background: linear-gradient(0deg, rgba(235, 240, 247, 1) 0%, rgba(235, 240, 247, 0) 100%);
  }

  h4 {
    position: absolute;
    width: calc(100% - 1rem);
    background: #ebf0f7;
    height: 2em;

    &::after {
      content: '';
      position: absolute;
      top: 2em;
      height: 2em;
      width: 100%;
      display: block;
      background: linear-gradient(180deg, rgba(235, 240, 247, 1) 0%, rgba(235, 240, 247, 0) 100%);
    }
  }

  .wrapper {
    font-weight: bold;
    overflow-y: auto;
    padding-top: 4em;
    padding-bottom: 1em;
    margin-bottom: 0;
    width: 100%;

    &::-webkit-scrollbar-track {
      background: white;
    }
  }

  ol {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    i {
      font-weight: normal;
    }
  }

  ol ol {
    margin-bottom: 1em;
    margin-top: .5em;
    font-weight: normal;
  }

  li {
    margin-bottom: 0.2em;
    // segment title
    & > p {
      margin-bottom: 0;
    }
    // segment speakers
    & > i {
      font-size: 80%;
    }
  }
}
