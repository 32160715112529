// DraCor customizations to CETEIcean.css

tei-TEI tei-head {
  font-family: Rubik, sans-serif;
}
tei-titlePage {
  display: block;
  margin-bottom: 3em;
  tei-docTitle {
    display: block;
    margin-bottom: 1.5em;
    tei-titlePart {
      display: block;
      margin-bottom: .5em;
      font-size: 170%;
      &[type=main] {
        font-size: 200%;
        font-weight: 400;
      }
    }
  }
  tei-docAuthor {
    font-size: large;
  }
}
tei-body > tei-head {
  text-indent: 0;
  padding-top: 1.8em;
}
tei-div[type='epigraph'] {
  padding: 2em;
  padding-bottom: 1em;
  font-style: italic;
}
tei-teiheader + tei-text > tei-front > tei-div > tei-head {
  padding-top: 0;
}
tei-div > tei-head {
  text-indent: 0;
  padding-top: 1.7em;
}
tei-div > tei-div > tei-head {
  padding-top: 1.55em;
}
tei-div > tei-div > tei-div > tei-head {
  padding-top: 1.4em;
}
tei-div > tei-div > tei-div > tei-div > tei-head,
list > tei-head {
  padding-top: 1.3em;
}
tei-div > tei-div > tei-div > tei-div > tei-div > tei-head {
  padding-top: 1.2em;
}
tei-div > tei-div > tei-div > tei-div > tei-div > tei-div tei-head {
  padding-top: 1.1em;
}
tei-figure > tei-head {
  font-family: Rubik, Serif;
}
tei-teiHeader {
  display: none;
}
tei-titleStmt > tei-title {
  font-family: Rubik, sans-serif;
}
