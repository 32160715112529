@import '../mixins.scss';

.main {
  color: var(--background-light);
  background-color: var(--main);
  padding-top: var(--bootstrap-padding);
  padding-bottom: 0;
  z-index: 1;


  h1 {
    @include pagetitle;
  }

  // wraps the title, years, authors and meta information
  .play {
    display: flex;
    position: relative;
    gap: 2em;
    padding-left: var(--bootstrap-padding);
    padding-right: var(--bootstrap-padding);
    background: var(--main);

    &::after {
      content: '';
      position: absolute;
      right: 0;
      height: 100%;
      width: 3em;
      background: linear-gradient(
        to left,
        var(--main),
        var(--main),
        var(--main-transparent)
      );
    }

    .title {
      min-width: 36ch;
      max-width: 50%;

      .subtitle {
        margin: 0;
        padding-top: 1rem;
        font-size: 1.3em;
        font-weight: 400;
        font-style: italic;
      }

      .meta {
        display: block;
        margin-top: 1em;

        .dracor-id {
          margin-right: 1em;
          font-weight: 500;
          background: var(--background-light);
          border-radius: 0.3em;
          vertical-align: text-bottom;
          display: inline-flex;
          align-items: center;
          

          img {
            height: 0.85em;
            width: 0.85em;
            margin: 0 0.4em;
            padding: 0.01em;
            vertical-align: text-top;
          }

          svg {
            color: var(--main);
            margin: 0 0.4em 0.1em;
          }

          a,
          span {
            color: var(--primary-accent);
            font-weight: normal;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            line-height: 1.4;

            &:hover {
              color: #0056b3;
            }

            &:not(:active) > svg {
              color: var(--main);
              transition: color 1s step-end;
              animation: FadeOutIn 1s linear;
            }

            &:active > svg {
              color: var(--primary-accent);
            }
          }
        }

        .data-link {
          margin-right: 1em;
        }

        .years {
          vertical-align: text-bottom;
          white-space: nowrap;

          span {
            margin-right: 1rem;
          }
        }
      }
    }

    .authors {
      display: flex;
      flex-wrap: wrap;
      gap: 2em;
      min-width: 50%;
      overflow-x: auto;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        gap: var(--bootstrap-padding);
        margin-bottom: 1em;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: var(--bootstrap-padding);
    }
  }
}

.sticky-headings {
  display: inline-flex;
  flex-flow: column;
  margin-left: 1em;

  p {
    margin-top: 0.15em;
  }

  span {
    display: inline-flex;
  }

  h3 {
    padding: 0 0 0 0.5rem;
    &::before {
      content: '·';
      margin-right: 0.5rem;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: var(--x);
    }

    &:first-of-type::before {
      content: '';
      margin: 0;
    }
  }
}

:global(.sticky-outer-wrapper) {
  margin: 0;

  &:global(.active) {
    padding: 0;
    margin: 0;

    p {
      display: none;
    }

    h3 {
      padding-top: 0.42em;
      font-size: 1em;
      span {
        display: none;
      }
    }

    h1:first-of-type {
      font-size: 1.73em;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 0;
      padding-right: var(--x);
      text-decoration: underline;
      text-decoration-color: var(--secondary-accent);
      text-decoration-color: var(--secondary-accent);
      text-decoration-thickness: 0.1em;
    }
  }
}

:global(.sticky-inner-wrapper) {
  display: flex;
  flex-wrap: wrap;
  background: var(--main);
  padding-left: var(--bootstrap-padding);
  padding-right: var(--bootstrap-padding);
  // padding-right: var(--x);

  & > span {
    padding: var(--bootstrap-padding) 0 0;
    width: 100%;
    min-height: 4.5em;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    &::after {
      content: '';
      width: var(--x);
      height: 2em;
      background-image: linear-gradient(
        to left,
        var(--main),
        var(--main-transparent)
      );
      position: absolute;
      right: var(--x);
    }

    @media (max-width: 767px) {
      width: 100%;
      padding-bottom: 0;
      :global(.sticky-outer-wrapper):global(.active) &::after {
        height: 4.5em;
      }
    }
  }

  h1:first-of-type {
    font-size: 0;
    padding: 0;
    text-decoration: none;
  }

  h3 {
    font-weight: normal;
    font-size: 0;
    line-height: 1;
    margin: 0;

    span {
      font-size: 0.6em;
      vertical-align: middle;
    }
  }
}
