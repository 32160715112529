.main {
  // FIXME: this overrides the boostrap grid, we should find a more compatible
  // solution.
  margin-left: calc(var(--bootstrap-padding) * -1);
  margin-right: calc(var(--bootstrap-padding) * -1);

  flex-flow: nowrap;
  white-space: nowrap;
  border-bottom: none;
  overflow-x: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  background: var(--main);
  padding: 0 var(--bootstrap-padding);
  min-height: 2em;

  &::after {
    content: '';
    width: var(--bootstrap-padding);
    height: 2.5em;
    background-image: linear-gradient(
      to left,
      var(--main),
      var(--main-transparent)
    );
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    & {
      padding: 0 var(--bootstrap-padding);
    }

    & li:last-child::after {
      content: '';
      width: 1.3em;
      height: 1.3em;
      display: block;
      position: absolute;
      top: 0;
      right: -1.3em;
    }
  }
}
