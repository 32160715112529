.main {
  font-size: 0.9rem;
  line-height: 1.65;
  padding-top: var(--bootstrap-padding);
  padding-bottom: var(--bootstrap-padding);
  background: linear-gradient(to top, #ebf0f6, #e3e8f1);
  margin-top: 3em;

  & > * {
    flex: 1 1;
    height: 100%;
  }

  p {
    margin-bottom: 0;
  }

  h5 {
    width: 100%;
    white-space: nowrap;
  }
}

.citation {
  display: flex;
  flex-flow: row wrap;

  p {
    flex: 1 0 0;
    place-self: flex-end;
  }

  img {
    height: 5em;
    padding-top: 0.3rem;
    padding-right: 1rem;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 0.85;
    }
  }
}

.license {
  text-align: right;
  white-space: nowrap;

  a img {
    margin: 0 0 0.3rem 0.3rem;
    height: 1.3rem;
    @media only screen and (max-width: 767px) {
      margin: 0 0 0.3rem 0.3rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .citation,
  .license {
    text-align: left;
    white-space: normal;
    margin-bottom: 1em;
    flex: 1 1 100%;

    & h5 {
      white-space: normal;
    }
  }
}
