/* rubik-300 - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('./fonts/rubik-v21-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/rubik-v21-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-regular - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./fonts/rubik-v21-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/rubik-v21-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-500 - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('./fonts/rubik-v21-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/rubik-v21-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-700 - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('./fonts/rubik-v21-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/rubik-v21-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-300italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('./fonts/rubik-v21-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/rubik-v21-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('./fonts/rubik-v21-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/rubik-v21-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-500italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  src: local(''),
       url('./fonts/rubik-v21-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/rubik-v21-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-700italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('./fonts/rubik-v21-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/rubik-v21-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
  --main: #1f2448;
  --main-transparent: #1f244800;
  --main-shade: #1f24480a;
  --main-shadow: #1f244820;
  --shadow: #1f244835;
  --background: #ebf0f7;
  --background-light: #ffffff;
  --primary-accent: #0088ff;
  --secondary-accent: #aaeeff;
  --bootstrap-padding: 15px;
}

html,
#root {
  height: 100%;
  font-family: 'Rubik', sans-serif;
  position: relative;
}

body {
  height: auto;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: var(--main);

  &::after {
    content: '';
    position: fixed;
    height: 50%;
    width: 100%;
    background-color: var(--background);
    bottom: 0;
    z-index: -1;
  }
}

#root {
  background-color: var(--background);
  height: auto;
  margin-top: calc(5 * var(--bootstrap-padding));
}

ul {
  padding-inline-start: 1.2em;
  margin-block-end: 2em;
}

a {
  color: var(--primary-accent);
  transition: color 0.3s ease-in-out;
}

a:hover {
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

/* ############################# */
/* #NAVIGATION                 # */
/* ############################# */

// FIXME: move navbar overrides to TopNav.module.scss
.navbar {
  z-index: 3;
}

.navbar .navbar-nav .nav-link {
  color: var(--background-light);
  border-bottom: solid transparent 0.17em;
  margin-right: 1.5em;
  padding: 0;
  transition: border-bottom 0.3s ease-in-out;
}

.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus {
  color: var(--background-light);
}

.navbar .navbar-nav .nav-link:hover {
  border-bottom-color: var(--secondary-accent);
}

.navbar .nav-item:last-child .nav-link {
  margin-right: 0;
}

.nav-link span {
  display: none;
}

/* #HEADROOM */

.headroom-wrapper {
  z-index: 2;
  margin-top: calc(-5 * var(--bootstrap-padding));
  position: relative;
  min-height: calc(5 * var(--bootstrap-padding));
  max-height: 100%;
}

.headroom {
  background: var(--main);
  top: 0;
  left: 0;
  right: 0;
}

.headroom--unfixed {
  transform: translateY(0);
  position: relative;
}

.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}

.headroom--unpinned {
  position: relative;
  transform: translateY(-100%);
}

.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
  left: 0;
  right: 0;
  z-index: 2;
}

/* ############################# */
/* #PAGE                       # */
/* ############################# */

.dracor-page hgroup {
  color: var(--background-light);
  background-color: var(--main);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 50vh;
  margin: 0;
}

.loading em {
  text-transform: uppercase;
  margin-left: 1ch;
}

.dracor-page img {
  max-width: 100%;
}

.dracor-page img[alt~='logo'] {
  height: 10em;
  padding: var(--bootstrap-padding);
}

.dracor-page img[alt~='card'] {
  max-height: 30em;
  margin: 0 var(--bootstrap-padding) var(--bootstrap-padding) 0;
  border-radius: 1em;
  box-shadow: 0 5px 5px 0 var(--shadow), 0 4px 0px 0 var(--main-shadow);
}

.dracor-page img[alt~='poster'] {
  max-height: 40em;
  box-shadow: 0 5px 5px 0 var(--shadow), 0 4px 0px 0 var(--main-shadow);
}

.dracor-page img[alt~='shirt'] {
  max-height: 40em;
}

/* ############################# */
/* #SLIDER                     # */
/* ############################# */

.corpus-card {
  display: inline-block;
  width: 100%;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 1em 0 rgba(0, 0, 0, 0.08),
    0 4px 0px 0 #33405220;
  transition: 0.3s ease-in-out;
  border-radius: 0.8em;
  background-color: var(--background);
  color: var(--main);
}

.corpus-card:hover,
.corpus-card:focus {
  box-shadow: 0px 10px 35px -15px rgba(0, 0, 0, 0.75),
    0 5px 1em 0 rgba(0, 0, 0, 0.08), 0 2px 0px 0 #33405220;
  outline: none;
}

.corpus-card a {
  color: var(--main);
}

.corpus-card a:hover {
  text-decoration: none;
}

.corpus-card h2 {
  background-color: var(--background-light);
  color: var(--main);
  padding: 0.75rem;
  margin-bottom: 0;
  border-radius: 0.4em 0.4em 0 0;
  font-weight: 500;
  font-size: 2.5rem;
  border: 0;
  width: auto;
}

.corpus-card h2 span {
  color: var(--background-light);
  background-color: var(--main);
  padding: 0.05rem 0.45rem;
  border-radius: 0.2em;
  margin-right: 0.05em;
  font-weight: 400;
  text-transform: capitalize;
}

.corpus-card h3 {
  font-weight: 200;
  font-size: 1.2em;
  padding: 0.35rem 0.75rem;
  background-color: var(--secondary-accent);
  margin: 0;
}

.corpus-card h3 a {
  color: var(--main);
}

.corpus-card table {
  width: calc(100% - 1.6em);
  margin: 0 0.8em 0.8em;
}

.corpus-card table tbody tr {
  height: 4em;
  border-bottom: thin solid;
}

.corpus-card table tbody tr:first-of-type {
  height: 3.5em;
}

.corpus-card table tbody tr th {
  vertical-align: bottom;
  font-weight: 500;
}

.corpus-card table tbody tr th span {
  font-weight: 300;
}

.corpus-card table tbody tr td {
  text-align: right;
  vertical-align: bottom;
  font-weight: 300;
}

.corpus-card table tbody tr td code {
  color: var(--background);
  background-color: var(--main);
  padding: 0.1em 0.4em 0.15em 0.4em;
  border-radius: 1em;
  font-size: 0.8em;
}

.number-plays {
  font-size: 3em;
  line-height: 1em;
}

// adjust position of prev/next buttons
.slick-arrow.slick-next {
  right: 20%;
}
.slick-arrow.slick-prev {
  left: 20%;
}
@media (max-width: 1280px) {
  .slick-arrow.slick-next {
    right: 15%;
  }
  .slick-arrow.slick-prev {
    left: 15%;
  }
}

/* ############################# */
/* #CORPUS                     # */
/* ############################# */

.corpus-description {
  display: flex;
}

.corpus-description > div {
  width: calc(100% - 20em);
  margin-right: 2em;
}

.corpus-description a[rel~='licence']:after {
  position: absolute;
  margin-left: 0.3em;
  margin-top: 0.1em;
}

.corpus-description a[rel~="licence"][href="https://creativecommons.org/licenses/by-nc/3.0/deed.en_US"]:after
{
  content: url(./images/cc-by-nc.svg);
  width: 4em;
}

.corpus-description a[rel~="licence"][href="https://creativecommons.org/licenses/by-sa/3.0/us/"]:after
{
  content: url(./images/cc-by-sa.svg);
  width: 4em;
}

.corpus-description a[rel~="licence"][href="https://creativecommons.org/share-your-work/public-domain/cc0/"]:after
{
  content: url(./images/cc0.svg);
  width: 2.6em;
}

.search-label {
  align-self: right;
}

.search-label .form-control {
  color: var(--main);
  width: 20em;
  background-color: #fff;
  border: 0;
  border-radius: 2em;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16), 1px 3px 6px rgba(0, 0, 0, 0.23);
  transition: 2s ease-in-out;
}

.search-label .form-control:hover,
.search-label .form-control:focus {
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.16),
    inset 1px 3px 6px rgba(0, 0, 0, 0.23);
}

.react-bootstrap-table th .order-4:after,
.react-bootstrap-table th .caret-4-asc:after,
.react-bootstrap-table th .caret-4-desc:after {
  content: '\25BC' !important;
  font-size: 0.9em;
}

.react-bootstrap-table th .order-4:before,
.react-bootstrap-table th .caret-4-asc:before,
.react-bootstrap-table th .caret-4-desc:before {
  content: '\25B2' !important;
}

table a:hover {
  text-decoration: none;
}

.corpus-wrapper {
  margin: var(--bootstrap-padding) calc(0em - var(--bootstrap-padding));
  color: var(--main);
  width: calc(100% + var(--bootstrap-padding) * 2);
  overflow-x: auto;
}

table.table.corpus tr td:first-child {
  text-align: right;
  line-height: 1.65;
}

table.table.corpus tr:nth-child(odd) {
  background-color: var(--main-shade);
}

table.table.corpus tr:nth-child(even) {
  background-color: var(--background);
}

table.table.corpus th {
  background-color: var(--background);
  font-weight: 500;
  width: 10em;
}

table.table.corpus th:first-child {
  text-align: right;
}

table.table.corpus th:focus {
  outline: none;
}

.table-bordered th,
.table-bordered td {
  border: 0;
}

.table-bordered {
  border: 0;
}

.drama-title {
  font-size: 1.5em;
  color: var(--main);
  line-height: 1;
}

.year {
  font-weight: 400;
  font-size: 1.5em;
  line-height: 0.9;
}

.year-details {
  font-size: 60% !important;
}

.download-button {
  color: var(--background-light);
  border-radius: 0.5em;
  background-color: var(--main);
  padding: 0.5em 0.7em;
  line-height: 3.5em;
  box-shadow: 0 5px 5px 0 var(--shadow), 0 4px 0px 0 var(--main-shadow);
  white-space: nowrap;
  transition: all .3s ease-in-out;
}

.download-button:hover {
  text-decoration: none;
  color: var(--secondary-accent);
  box-shadow: 0 3px 0px 0 #33405220;
}

/* ############################# */
/* #PLAY                       # */
/* ############################# */

.dashboard-wrapper {
  display: block;
  padding-top: var(--bootstrap-padding);
  padding-bottom: var(--bootstrap-padding);
  margin-bottom: 0;
}

#dashboard {
  border: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

#dashboard > div {
  position: relative;
  height: fit-content;
  padding: 1em;
  border: 0;
  border-radius: 0.5em;
  background-color: var(--background-light);
  box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.2), 0 10px 1em 0 rgba(0, 0, 0, 0.08),
    0 4px 0px 0 #33405220;
}

.nav-tabs .nav-link {
  font-size: 1.2em;
  padding: 0.5em 0;
  margin: auto;
  border: none;
  color: var(--background-light);
}

.nav-tabs .nav-item {
  position: relative;
  display: flex;
  margin-bottom: 0;
  margin-right: 1.3em;
}

.nav-tabs .nav-item a::after {
  opacity: 0;
  height: 0.2em;
  background-color: var(--secondary-accent);
  display: block;
  content: '';
  border-radius: 0.3em 0.3em 0 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.nav-tabs .nav-link.active {
  color: var(--background-light);
  border: none;
  background: transparent;
}

.nav-tabs .nav-link.active::after {
  opacity: 1;
}

.nav-tabs .nav-item a:hover::after {
  opacity: 1;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: var(--secondary-accent);
  border: none;
  outline: none;
}

.tei-frame {
  width: inherit;
  display: flex;
  z-index: 0;

  .ceteicean {
    position: relative;
    overflow-y: auto;
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 3em;
    width: calc(100% - 1rem);
    z-index: 1;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 6em;
    width: calc(100% - 1rem);
    z-index: 1;
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
}

.speech-dist-container {
  padding: 1em;
  flex-direction: column;
}

.speech-dist-container div + div {
  padding-top: 2em;
}

.dracor-scrollbar {
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--background);
    border-radius: 0.25rem;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    background: var(--main);
    border: none;
    border-radius: 0.25rem;
  }
}

.version-pill {
  white-space: nowrap;
}

.version-pill > span {
  background-color: var(--main);
  color: var(--background);
  border-radius: 1em 0 0 1em;
  padding: 0.1em 0.3em 0.1em 0.5em;
}

.version-pill > a {
  background-color: var(--primary-accent);
  color: var(--background);
  border-radius: 0 1em 1em 0;
  padding: 0.1em 0.5em 0.1em 0.3em;
  transition: 0.3s ease-in-out;
}

.version-pill > a:hover {
  background-color: #0056b3;
  color: var(--secondary-accent);
}

@media only screen and (max-width: 1440px) {
  :root {
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 767px) {
  .headroom-wrapper {
    height: auto !important;
  }
  // FIXME: move navbar overrides to TopNav.module.scss
  .navbar .navbar-toggler-icon {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="rgb(255, 255, 255)"><rect y="0" width="100%" height="12%" rx="0%" ry="0%" /><rect y="40%" width="100%" height="12%" rx="0%" ry="0%" /><rect y="80%" width="100%" height="12%" rx="0%" ry="0%" /></svg>')
      center / 100% 100% no-repeat;
  }
  .navbar .navbar-nav .nav-link {
    display: block;
    color: #fff;
    border-bottom: solid #fff 0.17em;
  }
  .navbar .navbar-nav .nav-link.active {
    color: #fff;
  }
  .navbar .navbar-nav .nav-link:hover,
  .navbar .navbar-nav .nav-link:focus {
    color: var(--secondary-accent);
  }
  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    line-height: 2em;
  }
  .navbar .navbar-toggler {
    border: none;
  }
  .nav-link span {
    display: block;
  }
  .nav-link svg {
    display: none;
  }
  .navbar-collapse {
    padding-top: 1em;
  }
  .corpus-card {
    width: calc(100vw - 2.5em);
  }
  .corpus-card table {
    font-size: 0.9em;
  }
  .corpus-card table tbody tr {
    height: 3.5rem;
  }
  .corpus-description {
    flex-direction: column;
  }
  .corpus-description > div {
    width: 100%;
  }
  .search-label .form-control {
    width: 94vw;
    margin-top: 0.5em;
  }

  .tei-frame {
    overflow-y: hidden;
  }
  .tei-frame #TEI {
    padding: 0 !important;
  }
}
