.base {
  min-height: calc(5 * var(--bootstrap-padding));
  padding: var(--bootstrap-padding);
  // main entries in top navigation
  :global(.navbar-nav .nav-item),
  :global(.navbar-nav .dropdown .dropdown-toggle) {
    font-size: 1.2em;
    font-weight: 400;
    text-transform: uppercase;
  }

  :global(.dropdown-item.active) {
    color: var(--main);
    background-color: var(--secondary-accent);
  }

  :global(.navbar-brand) {
    content: ' ';
    background-image: url(../images/DraCor-white.svg);
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    font-size: 1em;
    width: calc(3 * var(--bootstrap-padding));
    height: calc(3 * var(--bootstrap-padding));
  }
}

// centered main navigation
.main {
  margin: auto;
}

// GitHub link
.github {
  margin: 0;
  padding: 0;
  border-bottom: none;
  width: calc(3 * var(--bootstrap-padding));
  height: calc(3 * var(--bootstrap-padding));

  :global(.fa-github) {
    width: 100%;
    height: 100%;
    font-size: 1rem;
  }

  :global(.nav-item .nav-link:hover) {
    border-bottom: none;
  }

  @media only screen and (max-width: 767px) {
    width: auto;
    height: auto;

    :global(.nav-item .nav-link:hover) {
      border-bottom: solid var(--secondary-accent) 0.17em;
    }
  }
}
