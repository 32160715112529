.main {
  border: 0;
  font-weight: 500;
  font-size: 1.2rem;
  width: fit-content;
  height: fit-content;
  color: var(--main);
  display: flex;
  background-color: var(--background-light);
  color: var(--main);
  padding: 2px 4px 2px 2px;
  border-radius: 0.3em;
  transition: font-size 0.3s ease-in-out;

  a {
    color: var(--main);
    display: flex;
    line-height: 1.2;

    em {
      color: var(--background-light);
      background-color: var(--main);
      padding: 0 0.25rem;
      border-radius: 0.2em;
      margin-right: 0.1em;
      font-weight: 400;
      font-style: normal;
      text-transform: capitalize;
      transition: background-color 0.2s ease-in-out;
    }

    &:hover > em {
      background-color: var(--primary-accent);
    }
  }

  // FIXME: This takes care of changing the size when switching on the sticky
  // header. It would be nice to find a solution that does not rely on external
  // class names.
  :global(.sticky-outer-wrapper.active) & {
    font-size: 2.1em;
    padding: 4px 8px 4px 4px;

    .dracor-id {
      width: 0;
    }
  }

  span {
    font-weight: normal;

    // DraCor ID link
    a,
    span {
      font-size: 85%;
    }
  }
}
