.main {
  font-size: 0.85rem;
  position: relative;
  display: flex;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    height: 2em;
    width: calc(100% - 1rem);
    bottom: 0;
    display: block;
    background: linear-gradient(0deg, rgba(235, 240, 247, 1) 0%, rgba(235, 240, 247, 0) 100%);
  }

  .header {
    position: absolute;
    display: block;
    top: 0;
    height: 3em;
    width: calc(100% - 1rem);
    background: #ebf0f7;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      top: 3em;
      height: 2em;
      width: 100%;
      display: block;
      background: linear-gradient(180deg, rgba(235, 240, 247, 1) 0%, rgba(235, 240, 247, 0) 100%);
    }
  }

  ol {
    overflow-y: auto;
    padding-top: 4em;
    padding-bottom: 1em;
    padding-inline-start: 0;
    margin-bottom: 0;
    width: 100%;
    list-style-position: inside;

    &::-webkit-scrollbar-track {
      background: white;
    }
  }

  // title
  h4 {
    font-size: 1.3rem;
    font-weight: normal;
    margin: 0;
  }

  // subtitle
  p {
    margin: 0 0 1em;
  }

  // group icon
  :global(.fa-users) {
    color: var(--primary-accent);
  }
  // gender icons
  :global(.fa-mars),
  :global(.fa-venus) {
    opacity: 0.4;
  }
  
  a img[alt=Wikidata] {
    display: inline-block;
    height: 0.8em;
  }
}
