/*
 * since bootstrap utilities don't allow to specify position depending on the
 * breakpoint we use a media query to style the cast list wrapper to be
 * scrollable on medium (and up) displays
 */

@media (max-width: 768px) {
  .speech-dist-container {
    flex-direction: column;
  }
}

.tei-frame {
  flex-grow: 1;
  position: relative;
}

/* fix overflow of CETEIcean content */
.tei-frame #TEI {
  padding: 35px;
}
